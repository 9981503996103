import React, { useState, useEffect } from 'react';
import { Row, Column, Button, Input, Select, Modal, Loader, PStyle } from '../../../edfm';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { ApiPath, SaveContact } from '../../../widgets/data';
import { contactProps, brokerDataProps, URLParams } from '../../../widgets/types';
import ValidateEmail from '../../../widgets/validateEmail';

const BrokerContact = (props: { contact?: contactProps; cancel?: any; brokerData: brokerDataProps }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { contact, cancel, brokerData } = props;

	const [contactData, setContactData] = useState(contact);
	const [path, setPath]: [string | undefined, any] = useState();
	const [updatedTs, setUpdatedTs] = useState(brokerData.updatedTs);
	const [updating, setUpdating] = useState(false);

	const [hideModal, setHideModal] = useState(true);
	const [modalBody, setModalBody] = useState('');
	const [modalTitle, setModalTitle] = useState('');

	const { id, contactId } = useParams<URLParams>();

	const [inputList, setInputList] = useState([{ emailAddress: '' }]);
	// handle click event of the Add button
	const setEmailInput = (): void => {
		if (contactData) {
			let emails: any = [];
			contactData.email.split(',').forEach((email) => emails.push({ emailAddress: email }));
			setInputList(emails);
		}
	};

	useEffect(() => {
		if (!contact) {
			setContactData({
				contactName: '',
				contactType: '',
				email: '',
				isActive: true,
			});
		} else {
			setEmailInput();
		}
		// adding `setEmailInput`  here causes tests to hang forever.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contact]);

	ApiPath((apiPath: { apiurl: string }) => {
		setPath(apiPath.apiurl);
	});

	const handleError = (errorMessage: string): void => {
		setModalBody(errorMessage);
		setModalTitle('Error');
		setHideModal(false);
	};

	const submitForm = (e: any): void => {
		e.preventDefault();

		if (contactData) {
			const submissionData: contactProps = { ...contactData };

			submissionData.updatedTs = updatedTs;

			const body = submissionData;
			const method = !contact ? 'POST' : 'PUT';

			setUpdating(true);

			const successFunc = (newTS: string, newId?: number): void => {
				setUpdating(false);

				if (newId) {
					//bust cache for broker list
					dispatch({ type: 'brokersData', data: undefined });
					dispatch({ type: 'brokerData', data: undefined });
					setUpdatedTs(newTS);
					history.push(`../contacts/edit-contact/${newId}`);
				} else {
					setModalBody('Contact updated');
					setModalTitle('Update successful');
					setHideModal(false);
					//bust cache for broker list
					dispatch({ type: 'brokersData', data: undefined });
					dispatch({ type: 'brokerData', data: undefined });
					setUpdatedTs(newTS);
				}
			};
			const errorFunc = (error: string): void => {
				//handle error
				handleError(error);
				setUpdating(false);
			};

			if (path) {
				//as apiPath *must* exist to reach this sub page - no current handling / subscription created in case not defined
				const savePath = contact ? `/tpi/${id}/contact/${contactId}` : `/tpi/${id}/contact`;
				SaveContact(savePath, method, body, successFunc, errorFunc);
			}
		}
	};

	const textChange = (e: any): void => {
		const value = e.target.value;
		const field = e.target.id;
		setContactData((oldData: any) => {
			const newData = {
				...oldData,
				[field]: value,
			};
			return newData;
		});
	};

	const handleSelectChange = (e: string | number, field: string): void => {
		setContactData((oldData: any) => {
			const newData = {
				...oldData,
				[field]: e,
			};
			return newData;
		});
	};

	const validForm = (): boolean => {
		if (
			!contactData ||
			contactData.contactName === '' ||
			contactData.contactType === '' ||
			contactData.email === '' ||
			!validateEmails()
		) {
			return false;
		}
		return true;
	};

	const cancelAction = (): void => {
		if (contact) {
			cancel();
		} else {
			history.push(`../contacts/`);
		}
	};

	// handle email change
	const handleInputChange = (e: any, index: number): void => {
		const emails = [...inputList];
		emails[index].emailAddress = e.target.value;
		setInputList(emails);
		if (contactData) {
			contactData.email = emails.map((email) => `${email.emailAddress}`).join(',');
		}
	};

	// handle click event of the Remove button
	const handleRemoveClick = (index: number): void => {
		const emails = [...inputList];
		emails.splice(index, 1);
		setInputList(emails);
		if (contactData) {
			contactData.email = emails.map((email) => `${email.emailAddress}`).join(',');
		}
	};

	// handle click event of the Add button
	const handleAddClick = (): void => {
		setInputList([...inputList, { emailAddress: '' }]);
	};

	const getEmailAddressId = (index: number): string => {
		return 'emailAddress' + index.toString();
	};

	//validate emails on submit
	const validateEmails = (): boolean => {
		let validEmails = [...inputList];
		return validEmails.filter((validEmail) => !ValidateEmail(validEmail.emailAddress)).reduce(() => false, true);
	};

	return (
		<>
			<Modal
				hide={hideModal}
				title={modalTitle}
				size="sm"
				customClose={() => setHideModal(true)}
				content={modalBody}
				close={true}
			/>

			{updating && (
				<div className="list-broker--loader">
					<Loader />
				</div>
			)}

			{contactData && (
				<>
					<Row>
						<Column columns={6}>
							<>
								<Input
									type="text"
									fullWidth
									value={contactData.contactName || ''}
									id="contactName"
									label="Name"
									onChange={textChange}
								/>
								{inputList.map((x, i) => {
									return (
										<div className="box">
											<Input
												type="text"
												fullWidth
												value={x.emailAddress}
												id={getEmailAddressId(i)}
												label="Email Address"
												onChange={(e) => handleInputChange(e, i)}
												datatestid={getEmailAddressId(i)}
											/>
											<PStyle className="u-pull-right">
												{inputList.length !== 1 && (
													<Button
														label="Remove"
														action={() => handleRemoveClick(i)}
														datatestid={getEmailAddressId(i) + 'Remove'}
													/>
												)}
												{inputList.length - 1 === i && (
													<Button label="Add" action={handleAddClick} datatestid={getEmailAddressId(i) + 'Add'} />
												)}
											</PStyle>
										</div>
									);
								})}
								<Select
									selected={contactData.contactType}
									heading="Contact type"
									options={[
										{ id: 'STATUS', label: 'Status' },
										{ id: 'STATUS_CC', label: 'Status CC' },
										{ id: 'COMMISSION', label: 'Commission' },
										{ id: 'PORTFOLIO', label: 'Portfolio' },
										{ id: 'RENEWALS', label: 'Renewals' },
									]}
									id="contactType"
									onChange={(e: any) => {
										handleSelectChange(e, 'contactType');
									}}
								/>
							</>
						</Column>
					</Row>
					<Row>
						<PStyle className="u-pull-right">
							<Button label="Cancel" action={cancelAction} />
							<Button
								label={contact ? 'Update contact' : 'Add new contact'}
								disabled={!validForm()}
								primary
								action={submitForm}
							/>
						</PStyle>
					</Row>
					{!validForm() && (
						<Row>
							<span className="u-pull-right additional-details">
								*{contactData.contactName === '' && 'Contact name is required. '}
								{(contactData.email === '' || !validateEmails()) && 'A valid email address is required. '}
								{contactData.contactType === '' && 'A contact type must be selected.'}
							</span>
						</Row>
					)}
				</>
			)}
		</>
	);
};

export default BrokerContact;
