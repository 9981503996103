import React, { useState } from 'react';
import { Row, Column, Button, Modal, Loader } from '../../../edfm';
import { useHistory, useParams } from 'react-router-dom';
import { ApiPath, SaveContact, LoadBroker } from '../../../widgets/data';
import { useDispatch } from 'react-redux';
import { contactProps, brokerDataProps, URLParams } from '../../../widgets/types';
import useACL, { Feature } from '../../../widgets/acl';

const BrokerContact = (props: { contact: contactProps; edit: any; brokerData: brokerDataProps }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [updating, setUpdating] = useState(false);
	const [path, setPath]: [string | undefined, any] = useState();

	const [hideModal, setHideModal] = useState(true);
	const [modalBody, setModalBody] = useState('');
	const [modalTitle, setModalTitle] = useState('');
	const [modalButtons, setModalButtons]: [
		[{ primary?: boolean; secondary?: boolean; label: string; action: any }] | undefined,
		any
	] = useState();

	const { id, contactId } = useParams<URLParams>();

	const { contact, edit, brokerData } = props;

	LoadBroker(Number(id));

	ApiPath((apiPath: { apiurl: string }) => {
		setPath(apiPath.apiurl);
	});

	const acl = useACL();

	const deletePrompt = (): void => {
		//show modal to confirm deletion
		setModalBody(
			`This will remove ${contact.contactName} from the system including stopping any status alerts currently in place.`
		);
		setModalButtons([
			{ label: 'Cancel', action: () => setHideModal(true) },
			{
				primary: true,
				label: 'Confirm deletion',
				action: () => {
					deleteContact();
				},
			},
		]);
		setModalTitle('Confirm deletion');
		setHideModal(false);
	};

	const deleteContact = (): void => {
		setUpdating(true);
		setHideModal(true);

		const successFunc = (newTS: string): void => {
			setUpdating(false);

			//bust cache and return to list
			dispatch({ type: 'brokerData', data: undefined });
			history.push(`../../contacts/`);
		};
		const errorFunc = (error: string): void => {
			//handle error
			setModalBody(error);
			setModalTitle('Error');
			setHideModal(false);
			setModalButtons([]);

			setUpdating(false);
		};

		if (path) {
			//as apiPath *must* exist to reach this sub page - no current handling / subscription created in case not defined
			const submitData = { ...contact };
			submitData.isActive = false;
			submitData.updatedTs = brokerData.updatedTs;

			const body = submitData;

			const savePath = `/tpi/${id}/contact/${contactId}`;
			SaveContact(savePath, 'PUT', body, successFunc, errorFunc);
		}
	};

	return (
		<>
			<Modal
				hide={hideModal}
				title={modalTitle}
				size="sm"
				customClose={() => setHideModal(true)}
				buttons={modalButtons}
				content={modalBody}
				close={true}
			/>

			{updating && (
				<div className="list-broker--loader">
					<Loader />
				</div>
			)}

			<Row>
				<p>
					<a
						href="./"
						onClick={(e) => {
							e.preventDefault();
							history.push('./');
						}}
					>
						back
					</a>
				</p>
			</Row>
			<Row>
				<Column columns={12}>
					<>
						<p>
							<strong>Name:</strong> {contact.contactName === '' ? 'not set' : contact.contactName}
						</p>
						<p>
							<strong>Contact type:</strong> {contact.contactType === '' ? 'not set' : contact.contactType}
						</p>
						{/* STATUS, STATUS_CC, COMMISSION, PORTFOLIO, RENEWALS */}
						<p>
							<strong>{contact.email.split(',').length > 1 ? 'Emails: ' : 'Email: '}</strong>
							{contact.email === '' ? 'not set' : contact.email.replace(',', ', ')}
						</p>
					</>
				</Column>
			</Row>
			{acl(Feature.EditBroker) && (
				<Row>
					<span className="u-pull-right">
						<Button label="Delete contact" action={deletePrompt} />
						<Button label="Edit contact details" primary action={edit} />
					</span>
				</Row>
			)}
		</>
	);
};

export default BrokerContact;
